'use client'

import Head from 'next/head'
import { useRouter } from 'next/navigation'
import { useSession } from 'next-auth/react'
import { Fragment, useEffect } from 'react'

import Header from '@components/header/Header'

function Home() {
  const router = useRouter()
  const { data: session, status } = useSession()

  useEffect(() => {
    if (session && status === 'authenticated') {
      router.push('/dashboard')
    }
  }, [session, status, router])

  return (
    <Fragment>
      <Head>
        <title>ROBOSALES.AI</title>
      </Head>
      <div className="pl-5 pr-5 pt-2.5 pb-2.5">
        <Header />
      </div>
    </Fragment>
  )

}

export default Home
