import Image from 'next/image'
import React, { useCallback } from 'react'
import { useRouter } from 'next/navigation'
import { Button } from '@nextui-org/button'
import { signIn, signOut, useSession } from 'next-auth/react'

import roboLogo from '../../../public/robo.svg'

const Header = () => {
	const router = useRouter()
	const { data: session } = useSession()

	const handleLogout = useCallback(async () => {
		const data = await signOut({ redirect: false, callbackUrl: '/' })
		router.push(data.url)
	}, [router])

	const handleSignIn = useCallback(() => {
		signIn('cognito')
	}, [])

	return (
		<div className="flex justify-between mb-8 mt-5">
			<a href="https://www.robosales.ai" target="_blank" rel="noreferrer">
				<Image
					priority
					src={roboLogo}
					alt="ROBOSALES.AI"
				/>
			</a>
			{!session ? <Button color="primary" size="md" onClick={handleSignIn}>Sign In</Button> : (
				<div className="flex justify-center items-center">
					<p className="mr-2">
						{session?.user && session.user.email}
					</p>
					<Button color="primary" size="md" onClick={handleLogout}>
						Logout
					</Button>
				</div>
			)}
		</div>
	)
}

export default Header
